import React from 'react'
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';

// import BackButton1 from "../../public/images/email.png"
import {  useNavigate } from 'react-router-dom';
import "../Styles/HomePage.css"


const Privacypolicy = () => {
    const button5=useNavigate()
    const navigate5=()=>
  {
    button5('/')
  }
   
  return (


    <section style={{backgroundColor:"#0D0D0D"}}>
        
        <Container fluid className="p-5">
        <img src="./images/backbutton.png" onClick={navigate5} alt="" className='arrow-img' ></img>
     
            <h1 style={{color:"white",marginTop:"2%"}} className='privacypolicy2'>Privacy Policy</h1>
            <p style={{marginTop:"30px",color:"white"}} className='policy-para'>This Privacy Policy explains how information about you is collected, used and disclosed when you use our website located at honc.io , or the honc mobile application (all available by Ravini Technologies Private Limited) or when you otherwise interact with us.</p>
<p style={{marginTop:"30px",color:"white"}}className='encourage' >We encourage you to read the Privacy Policy whenever using the honc platform or submitting any personal information to honc.</p>
<Accordion className='rounded-2' >
      <Accordion.Item eventKey="0" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header' style={{color:"white"}} >Information collection,use and sharing</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
            <div>
    <h4 className='info1'>Information you provide to us</h4>
    <p className='collect1'>We collect information you provide directly to us when you create an account, participate in any interactive features of the application, fill out a form, participate in a contest or promotion,<br></br> make a purchase, apply for a job, communicate with us via third party social media sites, request customer support or otherwise communicate with us. the types of information we may<br></br> collect include your name, email address, postal address, phone number, fax number, financial information (including credit or debit card number, expiration date and security code) and<br></br> any other information you choose to provide.</p>
    </div>
    <div>
    <h4 className='info1'>Information we collect about you when you access our site/application and from other sources</h4>
<p className='collect1'>When you access or use our site or application, we automatically collect information about you, including: login information, location information, usage and preference information<br></br> (cookies and other tracking technologies), transaction information, device information, stored information and files. information received about you from third parties (for ex: social media <br></br> sites, vendors, partners, etc)</p>
    </div>
    <div>
    <h4 className='info1'>Use of Information</h4>
<p className='collect1'>We may use information about you for various purposes, including but not limited to: maintaining and improving services, deliver the services you request, process transactions, respond to<br></br> comments, questions and provide customer service, communicate, monitor and analyse trends.</p>
    </div>
    <div>
    <h4 className='info1'>sharing of Information</h4>
<p className='collect1'>We may share information about you as follows or as otherwise with vendors, partners and other service providers who need access to carry out work on our behalf. We may share this<br></br> information with government agencies assisting us for crime prevention or investigation. We may disclose your personal information if we are required by law to do so or if you violate our<br></br> Terms and Conditions</p>
    </div>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="1" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >security</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p>We take reasonable measures to help protect information about you collected by us.please be aware that the transmission of information via the internet is not completely secure.<br></br> although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted through the honc platform.</p>
   
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="2" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >payments</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>all the payments via cards are processed using third party payment gateway, Razorpay. the card data is not stored on our servers. we also have the option of paying through UPI, Net<br></br> Banking and e-wallets. your purchase transaction data is only used as long as is necessary to complete your purchase transaction. any purchase information is not saved after completion<br></br> of the transaction.</p>
   
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="3" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >your consent</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>By using the honc platform, you agree and consent to the collection, transfer, use, storage, disclosure and sharing of your information. if you do not agree, please do not use or access<br></br> the honc platform.</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="4" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >survey</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p  className='collect1'>We value opinions and comments from our Users and frequently conduct surveys, online, on the Application. participation in these surveys is entirely optional. typically, the information<br></br> received is aggregated, and used to make improvements to website, pplication, other sales channels, services and to develop appealing content, features and promotions for members<br></br> based on the results of the surveys. identity of the survey participants is anonymous unless otherwise stated in the survey.</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="5" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className="privacy-header"  style={{color:"white"}} >policy changes</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>We may change this Privacy Policy from time to time. if we make any significant changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide<br></br> you with additional notice (such as adding a statement to our homepage or sending you a notification).</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="6" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className="privacy-header"  style={{color:"white"}} >refund policy</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>At honc, we aim to provide a satisfactory experience to all our customers who use our services. Service cancellation refund will be processed to the customer through their respective mode of payment of the service within 48hrs of cancellation request received.<br></br>

Please review the following policy regarding refunds:
</p>
<p className='header3 rounded-2'>Refund Eligibility:
</p>
<p className='collect1'>Service Not Rendered: If the service was not provided or completed due to circumstances beyond the customer's control or due to an error on the part of the service provider, a refund may be warranted.
</p>
<p className='collect1'>Cancellation Before Service: If the customer cancels the service appointment within a reasonable timeframe before the scheduled service and meets any cancellation policy requirements, they may be eligible for a refund.
</p>
<p className='collect1'>Technical Issues or Service Interruptions: If there are technical issues or service interruptions that prevent the completion of the service, the customer may be eligible for a refund.
</p>
<p className='collect1'>Accidental Duplicate Service Bookings: If the customer accidentally books the same service multiple times and notifies the service provider promptly, they may be eligible for a refund for the duplicate booking.
</p>
<p className='header3 rounded-2'>Refund Process: 
</p>
<p className='collect1'>To request a refund for a service, customers can also contact our customer support team at support@honc.io or +91 9949177071 with their details and reason for the refund request.<br></br>
Refund requests will be reviewed and processed within 7 business days.<br></br>
Approved refunds will be issued to the original payment method used for the service purchase.

</p>
<p className='header3 rounded-2'>Modifications to the Refund Policy:
</p>
<p className='collect1'>honc reserves the right to modify or update this refund policy at any time. Any changes will be communicated to subscribers via email or through our website.
</p>
<p className='header3 rounded-2'>Subscription refunds</p>
<p className='collect1'>Refunds for subscription cancellations will be calculated on a pro-rata basis using the original price, not the discounted price. The refund amount shall be credited to honc pocket. Please write to support@honc.io for refunds to be processed to your bank account</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="7" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className="privacy-header"  style={{color:"white"}} >cancellation policy</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>We understand that circumstances may arise where you need to cancel your booked service. Please review the following guidelines regarding cancellations:<br></br>
</p>
<p className='header3 rounded-2'>One-Time Services:
</p>
<p className='collect1'>For one-time services such as car washes or repairs, you may cancel your appointment up to 1 hour before the scheduled service time without incurring any cancellation fees.
</p>
<p className='header3 rounded-2'>Subscription Services:
</p>
<p className='collect1'>You have the freedom to cancel your subscription plan at any time, directly through your account on our platform. There are no restrictions on when you can cancel your subscription during the billing cycle.<br></br>
If you choose to cancel your subscription before the end of the billing cycle, we will issue a prorated refund for the unused portion of your subscription period. This ensures that you only pay for the services you've received.

</p>

<p className='header3 rounded-2'>Refunds: 
</p>
<p className='collect1'>Refunds for cancellations of one-time services or subscription plans will be issued in accordance with our refund policy. Please refer to our refund policy for detailed information on refund eligibility and processing.
</p>
<p className='collect1'>We appreciate your understanding and cooperation in adhering to our cancellation policy. If you have any concerns or require further assistance, please feel free to reach out to us.
</p>
        </Accordion.Body>


      </Accordion.Item>


      <Accordion.Item eventKey="8" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'style={{color:"white"}}>cookies</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>honc uses "cookies" to help you personalize your online experience. one of the primary purposes of cookies is to provide a convenience feature to save you time. if you choose to decline <br></br>cookies, you may not be able to fully experience the interactive features of the honc platform.</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="9" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >Contact us</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>If you have any questions about this Privacy Policy, please contact us at info@honc.io.</p>
        </Accordion.Body>


      </Accordion.Item>

      </Accordion>
        </Container>
    </section>
  )
}

export default Privacypolicy